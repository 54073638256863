import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VForm,{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"7"}},[_c('div',{staticClass:"title"},[_c('b',[_vm._v(_vm._s(_vm.item.product.title))])]),_c('div',{staticClass:"desc",staticStyle:{"width":"300px","height":"60px","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(_vm.item.product.description)+" ")]),(_vm.item.product.type === '2')?[(_vm.item.product.maxAllowedEntrances != null && _vm.item.product.maxAllowedEntrances > 0)?_c('div',{staticClass:"desc"},[_c('b',[_vm._v(_vm._s(_vm.$t('basket-info-amountOfEntrances')))]),_vm._v(" "+_vm._s(_vm.item.product.maxAllowedEntrances)+" ")]):_vm._e(),_c(VSelect,{attrs:{"items":_vm.registrationRelationsList,"label":_vm.$t('basket-choose-registrationNumber'),"item-value":"id","item-disabled":_vm.disableItem,"rules":_vm.globalCheckNotEmpty(_vm.selectedRegistrationRelationId)},on:{"change":_vm.emitOrderItemChange},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.registrationNumber + ' '))]),_c('i',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.customText(item)))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.registrationNumber + ' '))]),_c('i',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.customText(item)))])]}}],null,false,3934419932),model:{value:(_vm.selectedRegistrationRelationId),callback:function ($$v) {_vm.selectedRegistrationRelationId=$$v},expression:"selectedRegistrationRelationId"}})]:_vm._e()],2),_c(VCol,{staticClass:"text-center",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.item.amount)+" ")]),_c(VCol,{staticClass:"text-right",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.globalConvertMinorCurrencyToLocal(_vm.item.product.priceIncludingVatInMinorCurrency))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }